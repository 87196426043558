import React from "react"
import "./scheduledate.sass"
import axios from 'axios';
import Reaptcha from 'reaptcha';
import loadingImg from '../../../static/images/loading.gif'


class ScheduleDate extends React.Component{

    state = {
        form: {
            nombre: {value:"", isValid:false, isEmpty:false},
            telefono: {value:"", isValid:false, isEmpty:false},
            correo: {value:"", isValid:false, isEmpty:false},
            motivo: {value:"", isValid:false, isEmpty:false},
            notas: {value:"", isValid:false, isEmpty:false},
        },
        isFormValid: false,
        loading: false,
        done: false,
        captcha: false
    }

    handleInputChange = event => {
        const inputName = event.target.name
        const inputValue = event.target.value
        const inputType = event.target.type
        const form = this.state.form

        form[inputName].value = inputValue
        form[inputName].isValid = this.isInputValid(inputValue,inputType)
        form[inputName].isEmpty = this.isInputEmpty(inputValue)

        const isFormValid = this.isformValid(this.state.form,this.state.captcha)
        this.setState({form: form, isFormValid: isFormValid})
    }

    isInputValid(inputValue,inputType){
        if(inputValue.trim()){
            if(inputType==="email"){
                const hasAnAtOnEmail = inputValue.search("@")>0
                return hasAnAtOnEmail
            }
            else{
                return true
            }
        }
        else{
            return false
        }
    }

    sendEmail = () => {
        if(this.state.isFormValid){
          this.setState({loading:true})

          fetch('https://vj0jnj7x37.execute-api.us-west-2.amazonaws.com/default/Send_Mail_HoneyPot',
            {
              method: 'POST',
              mode: 'cors',
              body: JSON.stringify({
                name: this.state.form.nombre.value,
                message: this.state.form.notas.value,
                email: this.state.form.correo.value,
                phone: this.state.form.telefono.value,
                reason: this.state.form.motivo.value,
                surname: 'Deja este espacio en blanco',
                address: 'Deja este espacio en blanco',
                title: 'Información del contacto',
                toAddress: 'fpgm14@yahoo.es',
              }),
            }).then((response) =>{
              this.setState({
                done: true,
                loading: false
              });
            })
        }
    }

  isformValid(form,isCaptchaSucessful){
    let isValid = true
    for (let key in form) {
      isValid = isValid && form[key].isValid
    }
    isValid = isValid && isCaptchaSucessful
    return isValid
  }

  isInputEmpty(inputValue){
    if(inputValue.trim()){
      return true
    }
    else{
      return false
    }
  }

  onVerify = recaptchaResponse => {
    const form = this.state.form
    const isFormValid = this.isformValid(form,true)
    this.setState({isFormValid: isFormValid, captcha: true})
  };

  showForm(){
    return !this.state.loading && !this.state.done
  }

  showLoading(){
    return this.state.loading && !this.state.done
  }

  showDone(){
    return !this.state.loading && this.state.done
  }

  render (){
    return (
      <div className="scheduleddate">
        <div className={this.showForm()?"scheduleddate__form-container":"scheduleddate__display-none"}>

          <div className="scheduleddate__title-wrap">
            <div className="scheduleddate__alert">
              <p className="scheduleddate__alert-important">!</p>
              <p> Recuerda usar este formulario para apartar citas. No se responderán consultas médicas por este medio.</p>
            </div>
            <h3 className="scheduleddate__title">Aparta tu cita</h3>
          </div>

          <form onSubmit={this.handleSubmit}>
            <div className="scheduleddate__input-wrap">
              <input
                className={this.state.form.nombre.isEmpty?"scheduleddate__input scheduleddate__input-valid":"scheduleddate__input"}
                type="text"
                name="nombre"
                value={this.state.form.nombre.value}
                onChange={this.handleInputChange}
                required
              >
              </input>
              <label className={this.state.form.nombre.isEmpty?"scheduleddate__label scheduleddate__label-valid":"scheduleddate__label"}>Nombre</label>
            </div>
            <div className="scheduleddate__input-wrap">
              <input
                className={this.state.form.telefono.isEmpty?"scheduleddate__input scheduleddate__input-valid":"scheduleddate__input"}
                type="text"
                name="telefono"
                value={this.state.form.telefono.value}
                onChange={this.handleInputChange}
                required
              >
              </input>
              <label className={this.state.form.telefono.isEmpty?"scheduleddate__label scheduleddate__label-valid":"scheduleddate__label"}>Teléfono</label>
            </div>
            <div className="scheduleddate__input-wrap">
              <input
                className={this.state.form.correo.isEmpty?"scheduleddate__input scheduleddate__input-valid":"scheduleddate__input"}
                type="email"
                name="correo"
                value={this.state.form.correo.value}
                onChange={this.handleInputChange}
                required
              ></input>
              <label className={this.state.form.correo.isEmpty?"scheduleddate__label scheduleddate__label-valid":"scheduleddate__label"}>Correo</label>
            </div>
            <div className="scheduleddate__input-wrap">
              <input
                className={this.state.form.motivo.isEmpty?"scheduleddate__input scheduleddate__input-valid":"scheduleddate__input"}
                type="text"
                name="motivo"
                value={this.state.form.motivo.value}
                onChange={this.handleInputChange}
                required
              ></input>
              <label className={this.state.form.motivo.isEmpty?"scheduleddate__label scheduleddate__label-valid":"scheduleddate__label"}>Motivo</label>
            </div>
            <div className="scheduleddate__input-wrap">
              <textarea
                className={this.state.form.notas.isEmpty?"scheduleddate__input scheduleddate__input-valid":"scheduleddate__input"}
                type="text"
                name="notas"
                value={this.state.form.notas.value}
                onChange={this.handleInputChange}
                required
              ></textarea>
              <label className={this.state.form.notas.isEmpty?"scheduleddate__label scheduleddate__label-valid":"scheduleddate__label"}>Comentarios</label>
            </div>
          </form>
          <Reaptcha className="scheduleddate__recaptcha" sitekey="6Ld5c2YUAAAAAC6y_aK9bE43TOB552ZPcCRG-Kzc" onVerify={this.onVerify}/>
          <button onClick={this.sendEmail}
            className={this.state.isFormValid?"scheduleddate__submit-button scheduleddate__submit-button-enabled":
          "scheduleddate__submit-button scheduleddate__submit-button-disabled"}

                    >Submit</button>
                </div>
                <div className={this.showLoading()?"scheduleddate__loading":"scheduleddate__display-none"}>
                  <img className="header__title-flower" alt="cargando" src={loadingImg}></img>
                </div>
                <div className={this.showDone()?"scheduleddate__done":"scheduleddate__display-none"}>
                  <h3 className="scheduleddate__thankyou">Muchas gracias por contactarme!</h3>
                  <div className="scheduleddate__thankyousub">Tu correo se ha enviado exitosamente y me pondré en contacto pronto. </div>
                </div>
              </div>
    )

  }
}

export default ScheduleDate
